<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Proxy Performance Report</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <select class="form-control" v-model="filter.provider">
                                <option selected value="">--Any Provider--</option>
                                <option v-for="item in providers" :key="item" :value="item">{{item}}</option>
                            </select>
                        </div>
                        <!--                    <div class="form-group mr-1">-->
                        <!--                        <select v-model="filter.client" class="form-control">-->
                        <!--                            <option value="" selected>&#45;&#45; Any Client &#45;&#45;</option>-->
                        <!--                            <option v-for="client in dropdownClients" :key="client.id" :value="client._id">{{client.name}}</option>-->
                        <!--                        </select>-->
                        <!--                    </div>-->
                        <!--                    <div class="form-group mr-1">-->
                        <!--                        <select v-model="filter.product" class="form-control">-->
                        <!--                            <option value="" selected>&#45;&#45; Any Product&#45;&#45;</option>-->
                        <!--                            <option v-for="product in products" :key="product._id" :value="product._id">{{product.name}}</option>-->
                        <!--                        </select>-->
                        <!--                    </div>-->
                        <button
                            type="button"
                            class="btn text-white mr-1"
                            @click="search"
                            style="background-color: #383838"
                        >
                            <i class="fa fa-search" title="Search"></i>
                            Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-success mr-1"
                            v-if="showExportButton"
                            @click="exportToExcel"
                        >
                            <i
                                class="fa fa-download"
                                title="Export"
                            >
                            </i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        :dataSource="report"
                        :allowExcelExport='true'
                        :allowPaging="true"
                        :allowSorting="true"
                        :allowFiltering="true"
                        :pageSettings='pageSettings'
                    >
                        <e-columns>
                            <e-column field='id' headerText="ID" :template="plTemplate"></e-column>
                            <e-column field='method' headerText="Method"></e-column>
                            <e-column field='provider' headerText='Provider'></e-column>
                            <e-column field='ip' headerText='IP'></e-column>
                            <e-column field='port' headerText='Port'></e-column>
                            <e-column field='request_uri' headerText='Request URI'></e-column>
                            <e-column field='status_code' headerText='Status Code'></e-column>
                            <e-column field='date' headerText="Date"></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import Vue from "vue";
import {
    GridPlugin,
    Page,
    Sort,
    ExcelExport,
    Group,
    Filter
} from "@syncfusion/ej2-vue-grids";
import axios from "axios";
import swal from "sweetalert";
import plTemplate from "@/components/plTemplate";


Vue.use(GridPlugin);

export default {
name: "Proxy.PerformanceReport",
    data: function(){
        return {
            filter:{
                to: '',
                from: '',
                provider:"",
                client: '',
                product: '',
            },
            dropdownClients: [],
            report:[],
            clients:[],
            products:[
                {key: "av", value:"Ad Verifier"},
                {key: "cc", value:"Content Catcher"},
                {key: "ev", value:"Email Verifier"},
            ],
            providers:[
                'Oxylabs',
                'Didsoft',
                "Crawlera",
                "Flipnode",
                "Homeip",
                "Luminate",
                "Proxyrack",
                "Smartproxy",
                "Netnut",
                "Proxyguys",
                "Spider",
                "Geosurf"
            ],
            proxyDataset: {},
            pageSettings: {pageSize: 100},
            searching: false,
            showExportButton: false,
            reportLoading: false,
            plTemplate: function () {
                return {
                    template : plTemplate
                }
            }
        }
    },
    created: function(){
        this.load();
        this.loadClients();
        this.loadProducts();
        this.GetClientsList();
        window.addEventListener("keydown",this.windowListener);
        this.$root.preloader = false;
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter
        ]
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        load: function () {
            this.filter.from = this.getDateFormatted(new Date());
            this.filter.to = this.getDateFormatted(new Date());
        },
        loadClients: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`, {params:request}).then(function(resp){
                //Store the stats
                this.clients = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        loadProducts: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.products = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        GetClientsList: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/clients/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClients = (resp.data)? resp.data.data : [];
            }.bind(this));
        },

        search: function(){
            this.searching = true;
            this.proxyDataset = {};
            this.report = [];
            this.reportLoading = true;

            var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};

            if(this.filter.from) request.filters.from = this.filter.from;
            if(this.filter.to) request.filters.to = this.filter.to;
            if(this.filter.provider) request.filters.provider = this.filter.provider;
            if(this.filter.client) request.filters.client_id = this.filter.client;
            if(this.filter.product) request.filters.product_id = this.filter.product;

            axios.get(`${this.$root.serverUrl}/admin/reports/proxy/performance`,{params:request}).then((resp) => {
                if(resp.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                }else{
                    const result = resp.data.data;
                    if(result.length > 0){
                        for (let i in result) {
                            this.stage(result[i]);
                        }
                        for(const item in this.proxyDataset){
                            this.report.push(this.proxyDataset[item]);
                        }
                        this.showExportButton = true;
                        this.reportLoading = false;
                    }else{
                        this.reportLoading = false;
                    }
                }
                this.searching = false;
            }).catch((err) => {
                this.reportLoading = false;
                this.searching = false;
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            });
        },
        stage: function (dataset) {
            if (!this.proxyDataset.hasOwnProperty(dataset._id)) {
                this.proxyDataset[dataset._id] = {
                    id: dataset._id,
                    provider: dataset.provider,
                    date: this.getDate(dataset.date),
                    method: dataset.method,
                    ip: dataset.ip,
                    port: dataset.port,
                    username: dataset.username,
                    password: dataset.password,
                    request_uri: dataset.request_uri,
                    status_code:dataset.status_code,
                    user_agent: dataset.user_agent,
                    message: dataset.message,
                    details:[]
                };
            }
            /*this.proxyDataset[dataset._id].error_count++;
            this.proxyDataset[dataset._id].details.push({

            });*/
        },
        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to}_Proxy_Performance_Report`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.report,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        },
        getDateFormatted: function(d){
            var
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        },
        getDateDifference: function(){
            const toDate = new Date(this.filter.to);
            let fromDate = new Date(this.filter.to);
            //fromDate.setDate(toDate.getDate()+1);  //day was off by a day
            fromDate.setMonth(toDate.getMonth()-2); //set two months in the past
            return fromDate;
        },
        fixDates: function(){
            this.filter.from = this.getDateFormatted(this.getDateDifference());
        }
    }
}
</script>

<style scoped>

</style>
