<template>
    <div>
        <a @click="viewDetails" style="cursor: pointer; color: blue">{{ data.id }}</a>
        <b-modal
            v-model="modalShow"
            size="xl"
            scrollable
            cancel-disabled
            :title="modalTitle"
        >
            <div class="row">
                <div class="col-12">
                    <table class="table table-sm table-bordered">
                        <tr>
                            <td><strong>ID</strong></td>
                            <td>{{ data.id }}</td>
                        </tr>
                        <tr>
                            <td><strong>Method</strong></td>
                            <td>{{ data.method }}</td>
                        </tr>
                        <tr>
                            <td><strong>Provider</strong></td>
                            <td>{{ data.provider }}</td>
                        </tr>
                        <tr>
                            <td><strong>IP Address/Domain Name</strong></td>
                            <td>{{ data.ip }}</td>
                        </tr>
                        <tr>
                            <td><strong>Port</strong></td>
                            <td>{{ data.port }}</td>
                        </tr>
                        <tr>
                            <td><strong>User Name</strong></td>
                            <td>{{ data.username }}</td>
                        </tr>
                        <tr>
                            <td><strong>Password</strong></td>
                            <td>{{ data.password }}</td>
                        </tr>
                        <tr>
                            <td><strong>Request URI</strong></td>
                            <td>{{ data.request_uri }}</td>
                        </tr>
                        <tr>
                            <td><strong>Status Code</strong></td>
                            <td>{{ data.status_code }}</td>
                        </tr>
                        <tr>
                            <td><strong>User Agent</strong></td>
                            <td>{{ data.user_agent }}</td>
                        </tr>
                        <tr>
                            <td><strong>Message</strong></td>
                            <td>{{ data.message }}</td>
                        </tr>
                        <tr>
                            <td><strong>Date</strong></td>
                            <td>{{ data.date }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <b-button size="sm" variant="primary" @click="modalShow = false">
                    OK
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';
import { TabPlugin } from '@syncfusion/ej2-vue-navigations';

Vue.use(TabPlugin);
export default {
    name: "columnTemplate",
    data: function() {
        return {
            data: {},
            fields:[
                { label: "Time", key: "time" },
                { label: "status", key: "status" },
                { label: "URL", key:"url" },
                { label: "Headers", key:"headers" },
                { label: "Request Headers", key:"request_headers" },
                { label: "Protocol", key: "data_protocol" },
                { label: "Host/IP", key: "data_ip" },
                { label: "Port", key: "data_port" }
            ],
            modalTitle:"Modal Title",
            modalShow:false,
        }
    },
    methods:{
        viewDetails: function(){
            this.modalTitle = `Report Details`;
            this.modalShow = true;
        }
    }
}
</script>

<style scoped>

</style>
